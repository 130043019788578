"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import statisticService from "@/store/services/statistic";
let StatisticModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "events", []);
    __publicField(this, "eventsPagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "eventsRedeemCount", 0);
    __publicField(this, "eventsLoaded", false);
    __publicField(this, "eventsLoading", false);
    __publicField(this, "coupons", []);
    __publicField(this, "couponsPagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "couponsRedeemCount", 0);
    __publicField(this, "couponsLoaded", false);
    __publicField(this, "couponsLoading", false);
    __publicField(this, "shops", []);
    __publicField(this, "shopsPagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "shopsRedeemCount", 0);
    __publicField(this, "shopsLoaded", false);
    __publicField(this, "shopsLoading", false);
    __publicField(this, "exportError", null);
    __publicField(this, "subscriptionsExcel", {});
    __publicField(this, "subscriptionsExcelLoaded", false);
    __publicField(this, "subscriptionsExcelLoading", false);
    __publicField(this, "usersExcel", {});
    __publicField(this, "usersExcelLoaded", false);
    __publicField(this, "usersExcelLoading", false);
  }
  listEvents(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateEventsLoadingStatus");
      const { data } = yield statisticService.listEvents(params);
      this.context.commit("mutateEvents", data);
    });
  }
  listCoupons(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateCouponsLoadingStatus");
      const { data } = yield statisticService.listCoupons(params);
      this.context.commit("mutateCoupons", data);
    });
  }
  listShops(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateShopsLoadingStatus");
      const { data } = yield statisticService.listShops(params);
      this.context.commit("mutateShops", data);
    });
  }
  resetStatistics() {
    this.context.commit("mutateResetStatistics");
  }
  listSubscriptionsExcel() {
    return __async(this, null, function* () {
      this.context.commit("mutateSubscriptionsExcelLoadingStatus");
      const { data } = yield statisticService.listSubscriptionsExcel();
      this.context.commit("mutateSubscriptionsExcel", data);
    });
  }
  generateSubscriptionsExcel() {
    return __async(this, null, function* () {
      yield statisticService.generateSubscriptionsExcel();
    });
  }
  listUsersExcel() {
    return __async(this, null, function* () {
      this.context.commit("mutateUsersExcelLoadingStatus");
      const { data } = yield statisticService.listUsersExcel();
      this.context.commit("mutateUsersExcel", data);
    });
  }
  generateUsersExcel() {
    return __async(this, null, function* () {
      yield statisticService.generateUsersExcel();
    });
  }
  exportSubscriptions(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateError", null);
      const { data } = yield statisticService.exportSubscriptions(params);
      return data;
    });
  }
  setError(error) {
    this.context.commit("mutateError", error);
  }
  mutateEvents(events) {
    this.events = events.items || [];
    this.eventsPagingInfo = events.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.eventsRedeemCount = events.meta.totalRedeemCount || 0;
    this.eventsLoaded = true;
    this.eventsLoading = false;
  }
  mutateCoupons(coupons) {
    this.coupons = coupons.items || [];
    this.couponsPagingInfo = coupons.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.couponsRedeemCount = coupons.meta.totalRedeemCount || 0;
    this.couponsLoaded = true;
    this.couponsLoading = false;
  }
  mutateShops(shops) {
    this.shops = shops.items || [];
    this.shopsPagingInfo = shops.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.shopsRedeemCount = shops.meta.totalRedeemCount || 0;
    this.shopsLoaded = true;
    this.shopsLoading = false;
  }
  mutateResetStatistics() {
    this.events = [];
    this.eventsLoaded = false;
    this.eventsLoading = false;
    this.coupons = [];
    this.couponsLoaded = false;
    this.eventsLoading = false;
    this.shops = [];
    this.shopsLoaded = false;
    this.shopsLoading = false;
  }
  mutateSubscriptionsExcel(excel) {
    this.subscriptionsExcel = excel;
    this.subscriptionsExcelLoaded = true;
    this.subscriptionsExcelLoading = false;
  }
  mutateUsersExcel(excel) {
    this.usersExcel = excel;
    this.usersExcelLoaded = true;
    this.usersExcelLoading = false;
  }
  mutateError(error) {
    this.exportError = error;
  }
  mutateEventsLoadingStatus() {
    this.eventsLoading = true;
  }
  mutateCouponsLoadingStatus() {
    this.couponsLoading = true;
  }
  mutateShopsLoadingStatus() {
    this.shopsLoading = true;
  }
  mutateSubscriptionsExcelLoadingStatus() {
    this.subscriptionsExcelLoading = true;
  }
  mutateUsersExcelLoadingStatus() {
    this.usersExcelLoading = true;
  }
  get getEvents() {
    return this.events;
  }
  get getEventsPagingInfo() {
    return this.eventsPagingInfo;
  }
  get getEventsRedeemCount() {
    return this.eventsRedeemCount;
  }
  get isEventsLoaded() {
    return this.eventsLoaded;
  }
  get isEventsLoading() {
    return this.eventsLoading;
  }
  get getCoupons() {
    return this.coupons;
  }
  get getCouponsPagingInfo() {
    return this.couponsPagingInfo;
  }
  get getCouponsRedeemCount() {
    return this.couponsRedeemCount;
  }
  get isCouponsLoaded() {
    return this.couponsLoaded;
  }
  get isCouponsLoading() {
    return this.couponsLoading;
  }
  get getShops() {
    return this.shops;
  }
  get getShopsPagingInfo() {
    return this.shopsPagingInfo;
  }
  get getShopsRedeemCount() {
    return this.shopsRedeemCount;
  }
  get isShopsLoaded() {
    return this.shopsLoaded;
  }
  get isShopsLoading() {
    return this.shopsLoading;
  }
  get getSubscriptionsExcel() {
    return this.subscriptionsExcel;
  }
  get isSubscriptionsExcelLoaded() {
    return this.subscriptionsExcelLoaded;
  }
  get isSubscriptionsExcelLoading() {
    return this.subscriptionsExcelLoading;
  }
  get getUsersExcel() {
    return this.usersExcel;
  }
  get isUsersExcelLoaded() {
    return this.usersExcelLoaded;
  }
  get isUsersExcelLoading() {
    return this.usersExcelLoading;
  }
  get hasExistingListError() {
    return this.exportError === "existing";
  }
  get hasEmptyListError() {
    return this.exportError === "empty";
  }
};
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "listEvents", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "listCoupons", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "listShops", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "resetStatistics", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "listSubscriptionsExcel", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "generateSubscriptionsExcel", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "listUsersExcel", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "generateUsersExcel", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "exportSubscriptions", 1);
__decorateClass([
  Action({ rawError: true })
], StatisticModule.prototype, "setError", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateEvents", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateCoupons", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateShops", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateResetStatistics", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateSubscriptionsExcel", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateUsersExcel", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateError", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateEventsLoadingStatus", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateCouponsLoadingStatus", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateShopsLoadingStatus", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateSubscriptionsExcelLoadingStatus", 1);
__decorateClass([
  Mutation
], StatisticModule.prototype, "mutateUsersExcelLoadingStatus", 1);
StatisticModule = __decorateClass([
  Module({ name: "statistic", namespaced: true })
], StatisticModule);
export {
  StatisticModule as default
};
